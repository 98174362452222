@charset "utf-8";

/*...VARIABLE...*/
$size: 10px;
$primary: #000000;
$secondary: #000000;
$link: #000000;
$bgButton: #90AF68;
$borderButton: #90AF68;
$buttonHover: #FFF;
$borderLine: #B2B2B2;
$bgFooter: #FFFFFF;
$bgSecondary: #F1F3F7;
$title: #000000;

$darkBrown: #2b1911;
$white: #FFF;
$blue: #4fa9ee;
$blueLight: #4fa9ee;
$orange: #FF9E00;
$orangeLight: #ffbe45;
$grey: #B3B3B3;
$green: #75ba97;
$purple: #a785d3;
$black: #000000;
$red: #e77374;
$redDark: #5E051B;
$pink: #eb6494;
$yellowDark: #d3b685;



$fontPrimary: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$fontMincho: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;