@charset "utf-8";

@import 'mixin';
@import 'variable';

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
img {
	max-width: 100%;
	display: inline-block;
	vertical-align: bottom;
}

#ifrm {
	display: none;
}

ul, ol, li {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
.h1, .h2, .h3, h1, h2, h3 {
	margin: 0;
	padding: 0;
}

.h4, .h5, .h6, h4, h5, h6 {
	margin: 0;
	padding: 0;
}


a {
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
	&:focus {
		color: inherit;
		text-decoration: none;
	}
}

.cont {
	width: 1200px;
	margin: auto;
	@media screen and (max-width: 1200px) {
		width: 90%;
		margin: 0 5%;
	}
}

.hv-o {
	@include transition(0.15s ease opacity);
	&:hover {
		@include opacity(0.7);
	}
}
.icon_bef:before{
	content:"";
	display:inline-block;
	width:20px;
	height:20px;
	background:url(https://file003.shop-pro.jp/PA01440/384/assets/img/icon/beforeicon1.png) no-repeat;
	background-size:contain;
}
.hv-u {
	&:hover {
		text-decoration: underline;
	}
}

.breadcrumb>li+li:before {
	display: none;
}

/*BG-SET*/
.bg-set{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.bold {
	font-weight: bold;
}

body {
	margin: 0;
	padding: 0;
	font-family: $fontPrimary;
	color: $primary;
	@include box-sizing(border-box);
	text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-o-text-size-adjust: 100%;
    overflow-x: hidden;
	font-size: 16px;
}
