@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';


body {
    background-image: url(../img/background/bgBody.png);
}

/* base font
===============================================================*/
/*
10px = 63%   |   17px = 106%   |   28px = 175%   
11px = 69%   |   18px = 113%   |   30px = 188%
12px = 75%   |   19px = 119%   |   32px = 20%
13px = 81%   |   20px = 125%
14px = 88%   |   22px = 138%
15px = 94%   |   24px = 150%
16px = 100%  |   25px = 156%
*/

@function pxtopercent($target, $parent: 16) {
    @return percentage($target / $parent);
}

@for $i from 10 through 40 {
    .font-#{$i} {
        font-size: pxtopercent($i);
    }
}



// 

/*HEADER*/
#header {
    width: 100%;
    top: 0;
    z-index: 999;
    @include transition(all .15s);
    @media screen and (max-width: 767px){
        position: fixed;
    }
    .firstRow {
        background-color: #90af68;
        .inner {
            @include flexbox;
            @include justify-content(space-between);
            @include align-items(center);
            height: 27px;
            @media screen and (max-width: 767px){
                height: 90px;
            }
            p {
                font-family: $fontMincho;
                font-size: 13px;
                @media screen and (max-width: 767px){
                    display: none;
                }
            }
            .navHead {
                @include flexbox;
                @include justify-content(flex-end);
                @include align-items(center);
                @media screen and (max-width: 767px){
                    display: none;
                }
                a {
                    @include flexbox;
                    @include align-items(center);
                    margin-left: 30px;
                    font-size: 12px;
                    @media screen and (max-width: 850px) {
                        margin-left: 15px;
                    }
                    img {
                        margin-right: 7px;
                        width: 20px;
                        @media screen and (max-width: 850px) {
                            margin-right: 5px;
                        }
                    }
                }
            }
            .logoSp {
                display: none;
                @media screen and (max-width: 767px){
                    display: block;
                    a {
                        img {
                            height: 75px;
                        }
                    }
                }
            }
            .iconNav {
                display: none;
                @media screen and (max-width: 767px) {
                    @include flexbox;
                    @include flex-direction(column);
                    @include justify-content(center);
                    @include align-items(center);
                    height: 40px;
                    width: 40px;
                    @include border-radius(0);
                }
                .hamburger {
                    padding: 0;
                    @include flexbox;
                    @include flex-direction(column);
                    @include align-items(center);
                    position: relative;
                    &:focus {
                        outline: none;
                    }
                    &:hover {
                        @include opacity(1);
                    }
                    .hamburger-box {
                        width: 40px;
                        .hamburger-inner {
                            @include border-radius(0);
                            width: 40px;
                            height: 3px;
                            background-color: #333333;
                            &:before {
                                @include border-radius(0);
                                width: 40px;
                                top: -10px;
                                height: 3px;
                                background-color: #333333;
                            }
                            &:after {
                                @include border-radius(0);
                                width: 40px;
                                height: 3px;
                                background-color: #333333;
                            }
                        }
                    }
                    &.hamburger--collapse {
                        .hamburger-inner {
                            &:after {
                                top: -20px;
                            }
                        }
                    }
                    &.is-active {
                        &:hover {
                            @include opacity(1);
                        }
                        .hamburger-inner {
                            &:before {
                                top: 0;
                            }
                            &:after {
                                top: 0;
                            }
                        }
                        .textMenu {
                            display: none;
                        }
                        .textClose {
                            display: block;
                        }
                    }
                }
            }

        }
    }
    .secondRow {
        background-image: url(../img/background/bgBook.png);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: 400px 200px;
        @media screen and (max-width: 767px){
            display: none;
        }
        .cont {
            .inner {
                height: 200px;
                @include flexbox;
                @include justify-content(space-between);
                padding: 25px 30px;
                @media screen and (max-width: 850px) {
                    padding: 25px 0;
                }
                .navigation {
                    width: 30%;
                    padding-left: 10px;
                    @media screen and (max-width: 850px) {
                        padding-left: 0;
                    }
                    nav {
                        -webkit-writing-mode: vertical-rl;
                        -moz-writing-mode: vertical-rl;
                        -ms-writing-mode: vertical-rl;
                        writing-mode: vertical-rl;
                        @include flexbox;
                        @include flex-direction(column-reverse);
                        height: 150px;
                        font-family: $fontMincho;
                        a {
                            margin-right: 20px;
                            -webkit-writing-mode: vertical-rl;
                            -moz-writing-mode: vertical-rl;
                            -ms-writing-mode: vertical-rl;
                            writing-mode: vertical-rl;
                            text-orientation: upright;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
                .logo {
                    width: 40%;
                    text-align: center;
                    a {
                        img {
                            height: 128px;
                        }
                    }
                }
                .search {
                    width: 30%;
                    @include flexbox;
                    @include justify-content(flex-end);
                    @include align-items(center);
                    padding-right: 30px;
                    @media screen and (max-width: 850px) {
                        padding-right: 0;
                    }
                    form {
                        input {
                            border: 1px solid #cecdcd;
                            background-color: #FFF;
                            width: 125px;
                            height: 25px;
                            font-size: 14px;
                            @include border-radius(30px);
                        }
                        button {
                            background-color: transparent;
                            border: 1px solid transparent;
                        }
                    }
                }
            }
        }

    }
    &.scroll {
    }
}

.paddingHeader {
    @media screen and (max-width: 767px){
        padding-top: 90px;
    }
}

/* NAV SP */
.navSp {
    display: none;
    @media screen and (max-width: 767px) {
        position: fixed;
        top: 90px;
        background-color: #FFFFFF;
        width: 100%;
        height: 100%;
        z-index: 100;
        padding: 0 0 100px;
        overflow-y: auto;
    }
    .search {
        width: 90%;
        margin: 30px auto;
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        form {
            input {
                border: 1px solid #cecdcd;
                background-color: #FFF;
                width: 250px;
                height: 30px;
                font-size: 14px;
                @include border-radius(30px);
            }
            button {
                background-color: transparent;
                border: 1px solid transparent;
                i {
                    font-size: 17px;
                }
            }
        }
    }
    ul.firstRow {
        width: 100%;
        margin-bottom: 30px;
        border-top: 1px dashed #c4c4c4;
        li {
            width: 100%;
            line-height: 50px;
            border-bottom: 1px dashed #c4c4c4;
            position: relative;
            a {
                display: block;
                position: relative;
                font-size: 15px;
                font-family: $fontMincho;
                padding: 0 15px;
                i {
                    position: absolute;
                    right: 15px;
                    top: 18px;
                    font-size: 13px;
                    &.up {
                        display: none;
                    }
                }
            }
            .subMenu {
                display: none;
                width: 100%;
                li {
                    line-height: 1;
                    &:first-child {
                        border-top: 1px dashed #c4c4c4;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                    a {
                        padding: 15px;
                        padding-left: 25px;
                        width: 100%;
                        display: block;
                        position: relative;
                    }
                }
            }
            .nav-change {
                @include border-radius(11px 11px 0 0);
                i {
                    &.down {
                        display: none;
                    }
                    &.up {
                        display: block;
                    }
                }
            }
        }
    }
    .navHead {
        @include flexbox;
        @include justify-content(center);
        @include align-items(center);
        a {
            @include flexbox;
            @include align-items(center);
            margin: 0 10px;
            font-size: 12px;
            img {
                margin-right: 7px;
                width: 20px;
                @media screen and (max-width: 850px) {
                    margin-right: 5px;
                }
            }
        }
    }
}

/*-----BREADCRUMB-----*/
.breadcrumbPage {
    height: 60px;
    overflow: hidden;
    @include flexbox;
    @include align-items(center);
    padding: 0 45px;
    @media screen and (max-width: 767px) {
        height: auto;
        padding: 8px 15px;
        display: none;
    }
    ul {
        @include flexbox;
        @media screen and (max-width: 480px) {
            @include flex-wrap(wrap);
        }
        li {
            font-size: 14px;
            letter-spacing: 1px;
            color: #707070;
            margin-right: 35px;
            a { 
                img {
                    height: 18px;
                    position: relative;
                    top: -2px;
                }
                &.active {
                    text-decoration: underline;
                }
                &:hover {
                    text-decoration: underline;
                }
            }
            &.arrow {
                position: relative;
                &:before {
                    position: absolute;
                    top: 50%;
                    left: -20px;
                    display: block;
                    content: '';
                    height: 8px;
                    width: 8px;
                    border: 1px solid #888;
                    border-left-width: 0;
                    border-top-width: 0;
                    -webkit-transform: translate(0, -50%) rotate(-45deg);
                    -ms-transform: translate(0, -50%) rotate(-45deg);
                    transform: translate(0, -50%) rotate(-45deg);
                }
            }
        }
    }
    
}


#footer {
    padding: 50px 0;
    background-image: url(../img/background/bgFooter.png);
    @media screen and (max-width: 767px) {
        padding-bottom: 15px;
    }
    .firstRow {
        padding-bottom: 40px;
        border-bottom: 1px solid #394e26;
        .inner {
            @include flexbox;
            @include justify-content(space-between);
            @media screen and (max-width: 850px){
                @include flex-direction(column-reverse);
                @include align-items(center);
            }
            .leftSide {
                @include flex(1);
                padding-top: 20px;
                @media screen and (max-width: 1024px){
                    padding-right: 25px;
                }
                @media screen and (max-width: 850px){
                    padding-right: 0;
                    @include flex(unset);
                    padding-top: 30px;
                    width: 100%;
                }
                .navFoot {
                    @include flexbox;
                    @include justify-content(flex-start);
                    margin-bottom: 40px;
                    @media screen and (max-width: 480px){
                        @include flex-wrap(wrap);
                    }
                    a {
                        @include flexbox;
                        @include justify-content(flex-start);
                        @include align-items(center);
                        font-size: 14px;
                        margin-right: 20px;
                        @media screen and (max-width: 480px){
                            width: 50%;
                            margin-right: 0;
                            margin-bottom: 10px;
                        }
                        i {
                            margin-right: 7px;
                        }
                        &:hover {
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                }
                .businessCalendar {
                    h3 {
                        margin-bottom: 10px;
                        font-size: 14px;
                    }
                    p {
                        width: 595px;
                        height: 145px;
                        @include flexbox;
                        @include justify-content(center);
                        @include align-items(center);
                        background-color: #b2b2b2;
                        color: #595959;
                        @media screen and (max-width: 850px){
                            width: 100%;
                        }
                    }
                }
            }
            .rightSide {
                width: 344px;
                border-left: 1px solid #394e26;
                padding-left: 50px;
                @media screen and (max-width: 1024px){
                    padding-left: 25px;
                }
                @media screen and (max-width: 850px){
                    border-left: 0;
                    padding-left: 0;
                }
                @media screen and (max-width: 480px){
                    width: 100%;
                }
                .logo {
                    margin-bottom: 10px;
                    @media screen and (max-width: 850px){
                        text-align: center;
                        margin-bottom: 30px;
                    }
                    a {
                        img {
                            height: 65px;
                        }
                    }
                }
                .textFoot {
                    p {
                        font-size: 13px;
                        font-feature-settings: "palt";
                        &.m {
                            margin: 10px 0;
                        }
                    }
                }
            }
        }
    }
    .secondRow {
        padding-top: 10px;
        .navFoot1 {
            @include flexbox;
            @include align-items(center);
            @include justify-content(flex-start);
            font-size: 13px;
            @media screen and (max-width: 767px) {
                @include justify-content(center);
                @include flex-wrap(wrap);
            }
            a {
                padding: 0 15px;
                border-right: 1px solid #000000;
                @media screen and (max-width: 767px) {
                    width: 50%;
                    border-right: 0;
                    text-align: center;
                }
                &:first-child {
                    padding-left: 0;
                    @media screen and (max-width: 767px) {
                        border-right: 1px solid #000000;
                    }
                }
            }
            p {
                padding-left: 15px;
                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding-left: 0;
                    text-align: center;
                    padding-top: 30px;
                }
            }
        }
    }
}

.mainvisualTopPage {
    .cont {
        @media screen and (max-width: 1200px) {
            margin: 0;
            width: 100%;
        }
        .swiper-container {
            width: 100%;
            height: 340px;
            margin-left: auto;
            margin-right: auto;
            @media screen and (max-width: 850px){
                height: 250px;
            }
            @media screen and (max-width: 767px){
                height: 50vw;
            }
            .swiper-wrapper {
                .swiper-slide {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }   
            }
            &.gallery-top {
                overflow: unset !important;
                @media screen and (max-width: 850px){
                    overflow: hidden !important;
                }
                .swiper-slide {
                    @include opacity(0.5);
                    &.swiper-slide-active {
                        width: 100% !important;
                        @include opacity(1);
                    }
                }
            }
            &.gallery-thumbs {
                padding: 15px 0 55px;
                width: 673px;
                margin: auto;
                height: 105px;
                box-sizing: border-box;
                @media screen and (max-width: 1024px) {
                    padding: 15px 0 30px;
                }
                @media screen and (max-width: 767px){
                    display: none;
                }
                .swiper-slide {
                    @include opacity(0.4);
                }
                .swiper-slide-thumb-active {
                    opacity: 1;
                }
            }
        }
    }
}

#breadcrumb {
    background-color: rgba($color: #e8e8e3, $alpha: 0.3);
    position: relative;
    ul {
        li {
            display: inline-block;
            font-size: 14px;
            padding: 5px 30px;
            position: relative;
            @media screen and (max-width: 767px) {
                padding: 5px 15px;
            }
            &:after {
                content: "";
                position: absolute;
                width: 1px;
                height: 35px;
                background-color: #a1a19d;
                transform: rotate(-30deg);
                right: 0;
                top: -2px;
            }
            &:first-child {
                padding-left: 0;
            }
            &:last-child{
                background-color: rgba($color: #bdbdb7, $alpha: 0.3);
                width: 100%;
                position:absolute;
                transform: skew(30deg);            }
            p{
                transform: skew(-30deg);
                opacity: 1 !important;
            }
        }
    }
}

#main {
    .flexContent {
        @include flexbox;
        @include justify-content(space-between);
        @media screen and (max-width: 767px){
            @include flex-direction(column);
        }
        .sideBar {
            width: 250px;
            background-color: #FFF;
            border: 1px solid #dae3d7;
            border-bottom: 0;
            padding: 95px 10px;
            position: relative;
            z-index: 10;
            @media screen and (max-width: 1200px) {
                width: 22%;
            }
            @media screen and (max-width: 850px) {
                padding: 30px 10px;
            }
            @media screen and (max-width: 767px){
                width: 100%;
                padding: 30px 15px;
            } 
            .contentSide {
                border-top: 1px solid #b1b1b1;
                h3 {
                    font-weight: bold;
                    padding-top: 10px;
                    margin-bottom: 20px;
                }
                .inner {
                    padding: 0 10px;
                    margin-bottom: 30px;
                    @media screen and (max-width: 767px){
                        padding: 0;
                        @include flexbox;
                        @include justify-content(space-between);
                        @include flex-wrap(wrap);
                    }
                    a {
                        @include flexbox;
                        @include justify-content(flex-start);
                        @include align-items(center);
                        margin-bottom: 10px;
                        @media screen and (max-width: 767px){
                            @include flex-direction(column);
                            width: 33%;
                        }
                        img {
                            width: 35px;
                            height: 35px;
                            margin-right: 10px;
                            @media screen and (max-width: 767px){
                                margin-right: 0;
                            }
                        }
                        span {
                            font-size: 14px;
                        }
                    }
                }
                .inner1 {
                    padding-left: 20px;
                    margin-bottom: 35px;
                    a {
                        @include flexbox;
                        @include justify-content(flex-start);
                        @include align-items(center);
                        margin-bottom: 10px;
                        i {
                            margin-right: 10px;
                        }
                        &:hover {
                            span {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
            .bannerBox {
                a {
                    margin-bottom: 10px;
                    display: block;
                    @media screen and (max-width: 767px){
                        text-align: center;
                        img {
                            width: 215px;
                        }
                    }
                }
            }
            .snsBox {
                @include flexbox;
                @include justify-content(flex-start);
                @media screen and (max-width: 767px){
                    @include justify-content(center);
                }
            }
        }
        .mainContent {
            @include flex(1);
            @media screen and (max-width: 767px){
                @include flex(unset);
                width: 100%;
            }
            .headContent {
                width: 100%;
                height: 108px;
                @include flexbox;
                @include justify-content(flex-start);
                @include align-items(center);
                border: 1px solid #737272;
                margin-bottom: 30px;
                background-image: url(../img/background/bgHeading.png);
                background-size: contain;
                background-position: right;
                background-repeat: no-repeat;
                @media screen and (max-width: 1024px) {
                    height: 100px;
                }
                h2 {
                    padding-left: 40px;
                    font-family: $fontMincho;
                    font-size: 26px;
                    letter-spacing: 2px;
                    font-weight: bold;
                    @media screen and (max-width: 1024px) {
                        padding-left: 20px;
                        font-size: 20px;
                    }
                    @media screen and (max-width: 767px) {
                        padding: 0 15px;
                        text-align: center;
                    }
                }
            }
            .subTitle {
                // background-color: #88A5C6;
                background-image: url(../img/background/bgSubTitle.png);
                background-size: cover;
                background-position: right;
                background-repeat: no-repeat;
                width: 100%;
                color: #FFF;
                font-size: 18px;
                font-weight: bold;
                padding: 6px 15px;
                padding-left: 72px;
                position: relative;
                margin-bottom: 20px;
                letter-spacing: 1.5px;
                position: relative;
                @media screen and (max-width: 767px) {
                    padding-left: 15px;
                }
                img {
                    position: absolute;
                    &.one {
                        left: 30px;
                        top: -18px;
                    }
                    &.two {
                        left: 15px;
                        top: -18px;
                    }
                    &.three {
                        left: 17px;
                        top: -18px;
                    }
                    &.four {
                        left: 16px;
                        top: -17px;
                    }
                    &.five {
                        left: 18px;
                        top: -17px;
                    }
                    &.six {
                        left: 18px;
                        top: -16px;
                    }
                }
            }
        }
    }
    /*TOP PAGE*/
    &.topPage {
        position: relative;
        .flexContent {
            .mainContent {
                padding: 50px 0;
                @media screen and (max-width: 767px){
                    padding: 30px 0;
                }
                .yukata {
                    position: absolute;
                    width: 100%;
                    height: 403px;
                    top: 0;
                    left: 0;
                    background: rgba(204, 204, 204, 0.3);
                    @media screen and (max-width: 1200px) {
                        height: auto;
                    }
                    @media screen and (max-width: 767px){
                        position: relative;
                        background: none;
                    }
                    .cont {
                        @media screen and (max-width: 767px){
                            width: 100%;
                            margin: 0;
                        }
                    }
                    .conceptImg {
                        width: 950px;
                        margin-left: auto;
                        margin-right: 250px;
                        position: relative;
                        @media screen and (max-width: 1200px) {
                            width: 78%;
                            margin-left: 0;
                            margin-right: auto;
                        }
                        @media screen and (max-width: 767px){
                            width: 100%;
                        }
                        img {
                            width: 100%;
                            position: relative;
                            top: -10px;
                            @media screen and (max-width: 1200px){
                                top: 0;
                            }
                        }
                        .btnBox {
                            position: absolute;
                            bottom: 57px;
                            width: 100%;
                            @include flexbox;
                            @include justify-content(center);
                            @media screen and (max-width: 1200px){
                                bottom: 25px;
                            }
                            @media screen and (max-width: 480px){
                                position: relative;
                                bottom: 0;
                                margin-top: 15px;
                            }
                            a {
                                background-color: #90af68;
                                width: 135px;
                                height: 30px;
                                color: #FFF;
                                position: relative;
                                font-size: 13px;
                                @include flexbox;
                                @include justify-content(center);
                                @include align-items(center);
                                @include border-radius(5px);
                                i {
                                    position: absolute;
                                    right: 13px;
                                }
                            }
                        }
                    }
                }
                .shopNews {
                    padding-top: 400px;
                    padding-right: 70px;
                    padding-bottom: 60px;
                    @media screen and (max-width: 1200px){
                        padding-top: 43%;
                        padding-right: 50px;
                    }
                    @media screen and (max-width: 767px){
                        padding-top: 50px;
                        padding-right: 0;
                    }
                    .heading {
                        @include flexbox;
                        @include justify-content(space-between);
                        @include align-items(center);
                        margin-bottom: 25px;
                        h3 {
                            @include flexbox;
                            @include align-items(center);
                            font-size: 20px;
                            font-family: $fontMincho;
                            line-height: 1;
                            img {
                                height: 18px;
                                margin-right: 10px;
                            }
                        }
                        .button {
                            @include flexbox;
                            @include justify-content(center);
                            @include align-items(center);
                            width: 73px;
                            height: 23px;
                            font-size: 13px;
                            border: 1px solid #949493;
                            i {
                                margin-right: 13px;
                            }
                        }
                    }
                    .inner {
                        ul {
                            li {
                                a {
                                    border-bottom: 1px dashed #000000;
                                    @include flexbox;
                                    @include justify-content(flex-start);
                                    @include align-items(flex-start);
                                    font-size: 15px;
                                    padding: 8px 0;
                                    @media screen and (max-width: 767px){
                                        @include flex-direction(column);
                                    }
                                    span {
                                        width: 105px;
                                    }
                                    p {
                                        @include flex(1);
                                    }
                                    &:hover {
                                        p {
                                            text-decoration: underline;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                .featured {
                    padding-right: 70px;
                    padding-bottom: 50px;
                    @media screen and (max-width: 767px){
                        padding-right: 0;
                        padding-bottom: 20px;
                    }
                    .heading {
                        @include flexbox;
                        @include justify-content(space-between);
                        @include align-items(center);
                        margin-bottom: 25px;
                        h3 {
                            @include flexbox;
                            @include align-items(center);
                            font-size: 20px;
                            font-family: $fontMincho;
                            line-height: 1;
                            img {
                                height: 18px;
                                margin-right: 10px;
                            }
                        }
                        .button {
                            @include flexbox;
                            @include justify-content(center);
                            @include align-items(center);
                            width: 73px;
                            height: 23px;
                            font-size: 13px;
                            border: 1px solid #949493;
                            i {
                                margin-right: 13px;
                            }
                        }
                    }
                    .inner {
                        @include flexbox;
                        @include justify-content(space-between);
                        @include flex-wrap(wrap);
                        @media screen and (max-width: 1024px) {
                            @include justify-content(flex-start);
                        }
                        @media screen and (max-width: 767px){
                            @include justify-content(space-between);
                        }
                        .item {
                            display: block;
                            width: 23%;
                            margin-bottom: 20px;
                            @include transition(all .5s);
                            @media screen and (max-width: 1024px) {
                                width: 31%;
                                margin-right: 3.5%;
                                &:nth-child(3),
                                &:nth-child(6) {
                                    margin-right: 0;
                                }
                            }
                            @media screen and (max-width: 767px){
                                width: 47.5%;
                                margin-right: 0;
                            }
                            .img {
                                width: 100%;
                                margin-bottom: 10px;
                            }
                            h2 {
                                font-size: 13px;
                                font-feature-settings: "palt";
                            }
                            &:hover {
                                h2 {
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*YUKATA*/
    &.yukata {
        .flexContent {
            .mainContent {
                padding: 65px 0 120px;
                padding-right: 70px;
                @media screen and (max-width: 1024px) {
                    padding: 30px 0 75px;
                    padding-right: 30px;
                }
                @media screen and (max-width: 767px) {
                    padding: 30px 0 50px;
                    padding-right: 0;
                }
                .inner {
                    .listContent {
                        margin-bottom: 50px;
                        .list {
                            margin-bottom: 60px;
                            .content {
                                padding: 0 20px;
                                line-height: 2;
                                .flex {
                                    @include flexbox;
                                    @include justify-content(flex-start);
                                    .rec {
                                        width: 16px;
                                        margin-right: 5px;
                                    }
                                    p {
                                        @include flex(1);
                                        span {
                                            margin-left: 15px;
                                            color: #a26ec7;
                                        }
                                    }
                                }
                                .flex1 {
                                    @include flexbox;
                                    @include justify-content(flex-start);
                                    @include align-items(flex-start);
                                    .rounded {
                                        @include flexbox;
                                        @include justify-content(center);
                                        @include align-items(center);
                                        color: #FFF;
                                        font-size: 16px;
                                        font-weight: bold;
                                        width: 38px;
                                        height: 38px;
                                        @include border-radius(50%);
                                        background-color: #e7a352;
                                        margin-right: 10px;
                                    }
                                    .in {
                                        @include flex(1);

                                    }
                                }
                            }
                        }
                    }
                    .about {
                        margin-bottom: 40px;
                        h2 {
                            font-size: 30px;
                            font-family: $fontMincho;
                            border-bottom: 1px solid #7f7e7d;
                            margin-bottom: 40px;
                        }
                        ul {
                            @include flexbox;
                            @include justify-content(space-between);
                            @include flex-wrap(wrap);
                            margin-bottom: 30px;
                            li {
                                width: 32%;
                                margin-bottom: 30px;
                                .img1 {
                                    @include flexbox;
                                    @include justify-content(center);
                                    @include align-items(center);
                                    width: 100%;
                                    height: 205px;
                                    margin-bottom: 15px;
                                }
                                .img2 {
                                    @include flexbox;
                                    @include justify-content(center);
                                    @include align-items(center);
                                    width: 100%;
                                    height: 350px;
                                    margin-bottom: 15px;
                                }
                            }
                        }
                        
                    }
                    .contact {
                        border: 1px solid #9d9d9d;
                        background-color: #FFF;
                        padding: 7px;
                        position: relative;
                        .head {
                            position: absolute;
                            top: 20px;
                            left: 0;
                            background-color: #90af68;
                            height: 34px;
                            color: #FFF;
                            width: 100%;
                            @include flexbox;
                            @include justify-content(center);
                            @include align-items(center);
                            font-weight: bold;
                        }
                        .inner {
                            border: 1px solid #9d9d9d;
                            @include flexbox;
                            @include justify-content(space-between);
                            height: 100px;
                            .one {
                                background-color: #f0dcb0;
                                width: 220px;
                                height: 100%;
                                @include flexbox;
                                @include align-items(flex-end);
                                p {
                                    width: 100%;
                                    height: 55px;
                                    @include flexbox;
                                    @include justify-content(center);
                                    @include align-items(flex-end);
                                    font-size: 20px;
                                    line-height: 1;
                                    padding-bottom: 15px;
                                    i {
                                        margin-left: 10px;
                                    }
                                }
                            }
                            .two {
                                @include flex(1);
                                @include flexbox;
                                @include justify-content(space-between);
                                @include align-items(flex-end);
                                height: 100%;
                                div {
                                    width: 50%;
                                    height: 55px;
                                    text-align: center;
                                    @include flexbox;
                                    @include justify-content(center);
                                    @include align-items(center);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*COOKIE POLICY*/
    &.cookie_policy {
        .flexContent {
            .mainContent {
                padding: 65px 0 120px;
                padding-right: 70px;
                @media screen and (max-width: 1024px) {
                    padding: 30px 0 75px;
                    padding-right: 30px;
                }
                @media screen and (max-width: 767px) {
                    padding: 30px 0 50px;
                    padding-right: 0;
                }
                .inner {
                    @media screen and (max-width: 767px) {
                        font-size: 15px;
                    }
                    .firstText {
                        line-height: 2;
                        margin-bottom: 30px;
                    }
                    .listContent {
                        .list {
                            .content {
                                padding: 0 20px;
                                margin-bottom: 60px;
                                @media screen and (max-width: 767px) {
                                    margin-bottom: 30px;
                                    padding: 0;
                                }
                                p {
                                    line-height: 2;
                                    &.title {
                                        font-weight: 500;
                                        margin-bottom: 5px;
                                        font-size: 18px;
                                        @media screen and (max-width: 767px) {
                                            font-size: 16px;
                                        }
                                    }
                                }
                                a {
                                    color: $blue;
                                }
                                ul {
                                    padding-left: 20px;
                                    li {
                                        list-style-type: disc;
                                        line-height: 2;
                                    }
                                }
                                table {
                                    width: 100%;
                                    line-height: 2;
                                    th,td {
                                        border-bottom: 1px solid;
                                        @media screen and (max-width: 850px) {
                                            word-break: break-word;
                                        }
                                    }
                                    th {
                                        font-weight: 500;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*COMING SOON*/
    &.comingsoon {
        .flexContent {
            .mainContent {
                padding: 65px 0 120px;
                padding-right: 70px;
                text-align: center;
                @media screen and (max-width: 1024px) {
                    padding: 30px 0 75px;
                    padding-right: 30px;
                }
                @media screen and (max-width: 767px) {
                    padding: 30px 0 50px;
                    padding-right: 0;
                }
                h2 {
                    font-size: 90px;
                    font-weight: 500;
                }
                p {
                    line-height: 2;
                }
            }
        }
    }
    /*PRODUCT*/
    &.product {
        .flexContent {
            .mainContent {
                padding: 65px 0 120px;
                padding-right: 70px;
                @media screen and (max-width: 1200px) {
                    width: 78%;
                }
                @media screen and (max-width: 1024px) {
                    padding: 30px 0 75px;
                    padding-right: 30px;
                }
                @media screen and (max-width: 767px) {
                    padding: 30px 0 50px;
                    padding-right: 0;
                    width: 100%;
                }
                @media screen and (max-width: 700px) {
                    padding: 0;
                }
                .inner {
                    .content{
                        @include flexbox();
                        @include flex-direction(row);
                        @include justify-content(space-around);
                        @media screen and (max-width: 700px){
                            @include flexbox();
                            @include flex-direction(column);
                        }
                        .left-content{
                            width: 45%;
                            max-width: 400px;
                            height: auto;
                            max-height: 700px;
                            @include flexbox();
                            @include flex-direction(column);
                            @include align-items(flex-start);
                            @media screen and (max-width:767px){
                                width: 40%;
                            }
                            @media screen and (max-width:1100px){
                                max-height: 600px;
                            }
                            @media screen and (max-width:1000px){
                                max-height: 550px;
                            }
                            @media screen and (max-width:900px){
                                max-height: 500px;
                            }
                            @media screen and (max-width:800px){
                                max-height: 400px;
                            }
                            @media screen and (max-width:700px){
                                width: 114%;
                                margin: 0 -6%;
                                max-width: 100%;
                            }
                            .swiper-container {
                                width: 100%;
                                height: 300px;
                                margin-left: auto;
                                margin-right: auto;
                                .swiper-wrapper :active{
                                }
                            }
                            .swiper-pagination{
                                @include flexbox();
                                @include justify-content(flex-end);
                                padding: 5px 20px;
                                opacity: 0.8;
                                font-size: pxtopercent(15);
                                letter-spacing: 1px;
                                background-color: #FFFFFF;
                                width: 90px;
                                position: absolute;
                                right: 0;
                                left: auto;
                            }
                            .swiper-slide {
                                background-size: cover;
                                background-position: center;
                            }
                            .gallery-top {
                                height: 80%;
                                width: 100%;
                                @media screen and (max-width:700px){
                                    height: 300px;
                                    width: 114%;
                                }
                                @media screen and (max-width:500px){
                                    height: 250px;
                                    width: 114%;
                                }
                            }
                            .gallery-thumbs {
                                height: 15%;
                                box-sizing: border-box;
                                padding: 10px 0;
                            }
                            .gallery-thumbs .swiper-slide {
                                width: 25%;
                                height: 100%;
                                opacity: 0.4;
                            }
                            .gallery-thumbs .swiper-slide-thumb-active {
                                opacity: 1;
                            }
                        }
                        .right-content{
                            @include flexbox();
                            @include flex-direction(column);
                            margin-left: 40px;
                            width: 52%;
                            @media screen and (max-width:768px){
                                margin-left: 25px;
                            }
                            @media screen and (max-width:767px){
                                width: 70%;
                            }
                            @media screen and (max-width:700px){
                                width: 100%;
                                margin-left: 0px;
                                margin-top: 10px;
                            }
                            .text-title{
                                // margin: 30px 0 35px -17px;
                                margin: 30px 0 35px 0px;
                                line-height: 45px;
                                font-size: pxtopercent(26);
                                @media screen and (max-width: 768px) {
                                    font-size: pxtopercent(22);
                                    line-height: 35px;
                                    margin: 25px 0 30px 0px;  
                                }
                                @media screen and (max-width: 700px) {
                                    font-size: pxtopercent(22);
                                    line-height: 35px;
                                    margin: 20px 0 25px 0px;  
                                }
                                @media screen and (max-width: 530px) {
                                    font-size: pxtopercent(20);  
                                }
                            }
                            .text-desc{
                                div{
                                    .select-group{
                                        margin-top: 8px;
                                        width: 100%;
                                        max-width: 360px;
                                        height: 35px;
                                        select{
                                            width: 100%;
                                            height: 100%;
                                            border: 1px solid #d2d2d2;
                                            padding: 0 5px;
                                            font-size: pxtopercent(14);
                                        }
                                    }
                                    .select-desc{
                                        margin-top: 10px;
                                        font-size: pxtopercent(12.4);
                                        line-height: 25px;
                                    }
                                }
                            }
                            .input-group{
                                margin-top: 30px;
                                font-size: pxtopercent(13);
                                input{
                                    margin-top: 8px;
                                    width: 120px;
                                    height: 35px;
                                    padding: 0 5px;
                                    border: 1px solid #d2d2d2;
                                }
                            }
                            .button-group{
                                margin-top: 35px;
                                width: 100%;
                                max-width: 440px;
                                height: 60px;
                                button{
                                    width: 100%;
                                    height: 100%;
                                    background-color: #b40000;
                                    border: 1px solid #d2d2d2;
                                    color: #FFFFFF;
                                    font-size: pxtopercent(16);
                                    border-radius: 10px;
                                }
                            }
                            .list{
                                margin-top: 20px;
                                line-height: 30px;
                                @media screen and (max-width:768px){
                                    font-size: pxtopercent(14.5);
                                }
                                @media screen and (max-width:767px){
                                    font-size: pxtopercent(16);
                                }
                            }
                            .label-group{
                                margin: 20px 0;
                                .sub-label{
                                    @include flexbox();
                                    @include justify-content(center);
                                    @include align-items(center);
                                    width: 155px;
                                    height: 30px;
                                    margin-bottom: 7px;
                                    font-size: pxtopercent(13);
                                    color: #FFFFFF;
                                    &.blue{
                                        background-color: #419a9f;
                                    }
                                    &.green{
                                        background-color: #7caa61;
                                    }
                                    &.yellow{
                                        background-color: #c8a352;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    /*RYOKAN*/
    &.ryokan {
        .flexContent {
            .mainContent {
                padding: 65px 0 120px;
                padding-right: 70px;
                @media screen and (max-width: 1024px) {
                    padding: 30px 0 75px;
                    padding-right: 30px;
                }
                @media screen and (max-width: 767px) {
                    padding: 30px 0 50px;
                    padding-right: 0;
                }
                .headContent{
                    background-image: url(../img/ryokan/bgHeading.png);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: right;
                }
                .inner {
                    .firstRow{
                        font-size: pxtopercent(16);
                        line-height: 40px;
                        @media screen and (max-width:768px){
                            line-height: 35px;
                        }
                        @media screen and (max-width:480px){
                            line-height: 30px;
                        }
                    }
                    .secondRow{
                        margin-top: 35px;
                        .alert{
                            width: 100%;
                            background-color: #ebf1e1;
                            padding: 20px;
                            font-size: pxtopercent(16);
                            border: 1px solid #bdbdbc;
                            line-height: 30px;
                        }
                    }
                    .thirdRow{
                        @include flexbox();
                        @include flex-direction(row);
                        @include justify-content(center);
                        @include align-items(center);
                        margin: 70px -35px 0;
                        @media screen and (max-width:480px){
                            margin: 50px -35px 0;
                        }
                        @media screen and (max-width:598px){
                            @include flexbox();
                            @include flex-direction(column);
                        }
                        .left, .right{
                            margin: 0px 55px;
                            text-align: center;
                        }
                        .title{
                            font-size: pxtopercent(24);
                            margin-bottom: 30px;
                            @media screen and (max-width:803px){
                                font-size: pxtopercent(22);
                            }
                            @media screen and (max-width:767px){
                                font-size: pxtopercent(24);
                            }
                        }

                    }
                    .fourthRow{
                        .fourthColumn{
                            margin-top:40px;
                            .text-title{
                                font-size: pxtopercent(24);
                                line-height: 20px;
                                .text-small{
                                    font-size: pxtopercent(10);
                                    color: #818181;
                                }
                            }
                            .text-desc{
                                margin-top: 5px;
                                font-size: pxtopercent(16);
                                line-height: 32px;
                                @media screen and (max-width:480px){
                                    line-height: 30px;
                                }
                            }
                        }
                        .fourthColumn:nth-child(1){
                            margin-top: 0px;
                        }
                        .fourthColumn:nth-child(2),.fourthColumn:nth-child(3){
                            margin-top: 20px;
                        }
                        
                    }
                    .fifthRow{
                        margin-top: 90px;
                        @media screen and (max-width:768px){
                            margin-top: 70px;
                        }
                        @media screen and (max-width:480px){
                            margin-top: 50px;
                        }
                        p{
                            font-size: pxtopercent(15);
                            color: #020201;
                            line-height: 35px;
                            color: #415f1a;
                            @media screen and (max-width:480px){
                                line-height: 30px;
                            }
                        }
                        .contact {
                            margin-top: 30px;
                            border: 1px solid #9d9d9d;
                            background-color: #FFF;
                            padding: 7px;
                            position: relative;
                            .head {
                                position: absolute;
                                top: 20px;
                                left: 0;
                                background-color: #90af68;
                                height: 34px;
                                color: #FFF;
                                width: 100%;
                                @include flexbox;
                                @include justify-content(center);
                                @include align-items(center);
                                font-weight: bold;
                            }
                            .inner {
                                border: 1px solid #9d9d9d;
                                @include flexbox;
                                @include justify-content(space-between);
                                height: 100px;
                                .one {
                                    background-color: #f0dcb0;
                                    width: 220px;
                                    height: 100%;
                                    @include flexbox;
                                    @include align-items(flex-end);
                                    p {
                                        width: 100%;
                                        height: 55px;
                                        @include flexbox;
                                        @include justify-content(center);
                                        @include align-items(flex-end);
                                        font-size: 20px;
                                        line-height: 1;
                                        padding-bottom: 15px;
                                        i {
                                            margin-left: 10px;
                                        }
                                    }
                                }
                                .two {
                                    @include flex(1);
                                    @include flexbox;
                                    @include justify-content(space-between);
                                    @include align-items(flex-end);
                                    height: 100%;
                                    div {
                                        width: 50%;
                                        height: 55px;
                                        text-align: center;
                                        @include flexbox;
                                        @include justify-content(center);
                                        @include align-items(center);
                                    }
                                }
                            }
                        }
                    }
                    .sixthRow{
                        margin-top: 35px;
                        width: 100%;
                        max-width: 620px;
                        height: 90px;
                        position: relative;
                        .banner{
                            border: 1px solid #bdbdbc;
                            // position: absolute;
                            width: 100%;
                            height: 100%;
                            background-image: url(../img/ryokan/banner.png);
                            background-size: cover;
                            background-repeat: no-repeat;
                            background-position: right;
                            p{
                                width: 100%;
                                height: 100%;
                                @include flexbox();
                                @include align-items(center);
                                padding: 0 25px;
                                font-size: pxtopercent(18);
                            }
                            img{
                                width: 20px;
                                height: 10px;
                                @include flexbox();
                                @include align-items(center);
                            }
                        }
                        
                    }    
                }
            }
        }
    }
}

/* 営業日カレンダー */
.tbl_calendar {
    border: none !important;
    border-collapse: collapse !important;
    border-spacing: 0 !important;
    float:left;
    margin-right:30px;
    font-size:14px;
    border-top: 1px solid #7a8c65 !important;
    border-right: 1px solid #7a8c65 !important;
  }
  .tbl_calendar th, .tbl_calendar td {
    padding: 4px !important;
    text-align: center !important;
    border-bottom: 1px solid #7a8c65 !important;
    border-left: 1px solid #7a8c65 !important;
  }
  .tbl_calendar th { background-color:#1e3708; color:#a4ad9d; font-size:12px;}
  .tbl_calendar caption { font-size:12px; font-weight:bold !important; color:#000000 !important;}
  .side_cal_memo { font-size:12px; padding-top:20px !important;}
